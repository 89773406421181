exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-blog-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/blog-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-blog-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-homepage-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/homepage-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-homepage-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-content-pages-contact-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx?__contentFilePath=/opt/build/repo/content/pages/contact/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-content-pages-contact-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-compartilhar-telefone-pode-ser-chato-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/opt/build/repo/content/posts/compartilhar-telefone-pode-ser-chato/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-compartilhar-telefone-pode-ser-chato-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-guidelines-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/opt/build/repo/content/posts/guidelines/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-guidelines-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-why-this-blog-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/opt/build/repo/content/posts/why_this_blog/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-why-this-blog-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tag-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/tag-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tag-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tags-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/tags-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tags-query-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

